<template>
  <div class="c2c-wrap">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
export default {
  name: 'C2C'
}
</script>
<style lang="less">
.c2c-wrap {
  background-color: #fff;
}
</style>
